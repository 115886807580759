import styled from 'styled-components'

// const red = '#74c0f2'
// 202107
// const red = '#B52619'
// 20210911
// const red = '#82beef'
// 20210919
// const red = '#f4aa3d'
// 20210923
// const red = '#de2710'
// 20210930
// const red = '#74c0f2'
// 20230206
const red='#74c0f2'
// b01604
const blue = "#00a1b4"
export const IndexWrap = styled.div `
  @font-face {
    font-family: 'homeFont';
    src: url('${process.env.REACT_APP_IMGBASE}/administrator/data/upload/portal/resource/img/home/font.ttf') format('truetype');
  }
  @media (min-width: 750px) {
    width: 100%;
    overflow: hidden;
    background: #ff9474;
    // 换肤-主体-背景图
    //background:url(${process.env.REACT_APP_IMGBASE}/administrator/data/upload/portal/resource/img/header/banner_22_08_10.jpg) center top repeat-y;
    // background:url(/img/skin/main_bg_2023_01_20.jpg) center top repeat-y;
    // background:url(/img/skin/main_bg_2024_10_29.png) no-repeat center top;
    // background:url(${process.env.REACT_APP_IMGBASE}/administrator/data/upload/portal/resource/img/header/banner_22_09_30.jpg) center top repeat-y;
    // background:url(${process.env.REACT_APP_IMGBASE}/administrator/data/upload/portal/resource/img/header/banner_22_01_30.jpg) center top repeat-y;
    // background:url(${process.env.REACT_APP_IMGBASE}/administrator/data/upload/portal/resource/img/header/banner_09_04.jpg) center top repeat-y;
    //background-size:auto 100%;
    background-size:cover;
    .modelBox-layer-bg {
      position:fixed;
      left:0;
      top:0;
      width:100%;
      height: 100vh;
      background:rgba(0,0,0,0.5);
    }
    .modelBox{
      width: auto;
      height:80vh;
      position:fixed;
      z-index: 2000000;
      top: 10vh;
      left: 50%;
      background:#fff;
      box-sizing: border-box;
      padding: 20px;
      border-radius: 10px;
      img{
        height:100%;
      }
      .icon{
        width:40px;
        height:40px;
        cursor: pointer;
        text-align: right;
        float: right;
      }
    }
    .hdxtImg{
      width:200px;
      cursor: pointer;
      position: fixed;
      left: 80px;
      bottom: 80px;
      z-index:20000000000;
    }
    .dcwjEWM {
      width:200px;
      cursor: pointer;
      position: fixed;
      left: 280px;
      bottom: 80px;
      z-index:20000000000;
    }
    .home_title {
      height: 32px;
      font-size: 32px;
      font-weight: bold;
      line-height: 32px;
      color: ${red};
      text-align: center;
      display: flex;
      flex-direction: row;
      align-items: center;
      margin: 60px auto 30px;
      text-align: center;
      justify-content: center;
      .i_small {
        width: 8px;
        height: 8px;
        background: #f45d5d;
      }
      .i_large {
        width: 16px;
        height: 16px;
        background: ${red};
        margin-left: 7px;
      }
      .i_small2 {
        width: 8px;
        height: 8px;
        background: #f45d5d;
      }
      .i_large2 {
        width: 16px;
        height: 16px;
        background: ${red};
        margin-right: 7px;
      }
      span {
        padding: 0 20px;
      }
    }
    // 换肤-友情链接-字体颜色
    .yqlj {
      width: 1240px;
      margin: 60px auto;
      .yqlj_title {
        font-size: 30px;
        color: #78c2f0;
        font-weight: bold;
        .yqlj_link {
        }
      }
      .yqlj_list {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        flex-wrap: wrap;
        .yqlj_link {
          padding: 8px 18px;
          line-height: 28px;
          font-size: 20px;
          margin-top: 28px;
          color: #78c2f0;
          font-weight: bold;
          display: inline-block;
        }
        .yqlj_link:hover {
          color: #78c2f0;
        }
      }
    }
    .more {
      margin-left: 300px;
      font-size: 18px;
      color: ${red};
    }
    .mb{
      width:1340px;
      margin:0 auto;
      background:rgba(255,255,255,1);
      padding:25px 0;
    }
    .new_title {
      width: 340px;
      height: 50px;
      font-size: 30px;
      color: ${red};
      margin: 8px auto 0;
      font-weight: bold;
      border-bottom: 2px solid #ddf2f5;
      display: block;
    }
    .new_title:hover {
      color: orange;
    }
    .news_pic {
      width: 340px;
      height: 200px;
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center center;
      overflow: hidden;
      position: relative;
      margin-bottom: 14px;
      .zd{
        width:50px;
        position: absolute;
        left:0;
        top:10px;
        img{
          width:100%;
        }
      }
      .news_pic_p {
        width: 100%;
        height: 100px;
        background: url(${process.env.REACT_APP_IMGBASE}/administrator/data/upload/portal/resource/img/edu/mask_03.png) center center no-repeat;
        background-size: 100% 100%;
        position: absolute;
        left: 0;
        bottom: 0;
        padding: 70px 30px 10px;
        box-sizing: border-box;
        color: #fff;
        font-size: 16px;
        align-items: flex-end;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      .news_pic_p_article {
        width: 100%;
        height: 100px;
        background: url(${process.env.REACT_APP_IMGBASE}/administrator/data/upload/portal/resource/img/edu/mask_03.png) center center no-repeat;
        background-size: 100% 100%;
        position: absolute;
        left: 0;
        bottom: 0;
        padding: 70px 30px 10px;
        box-sizing: border-box;
        color: #fff;
        font-size: 16px;
        align-items: flex-end;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
    .footer_link {
      width: 1240px;
      margin: 60px auto 0px;
      display: flex;
      flex-direction: row;
      // justify-content: space-between;
      justify-content:flex-start;
      .footer_link_tab {
        width: 290px;
        height: 160px;
        font-size: 28px;
        line-height: 160px;
        text-align: center;
        font-weight: bold;
        color: #fff;
        margin-right:30px;
      }
      .footer_link_tab:nth-of-type(1) {
        background: url(${process.env.REACT_APP_IMGBASE}/administrator/data/upload/portal/resource/img/home/index_45.png) 0 0 no-repeat;
        background-size: cover;
      }
      .footer_link_tab:nth-of-type(2) {
        background: url(${process.env.REACT_APP_IMGBASE}/administrator/data/upload/portal/resource/img/home/index_47.png) 0 0 no-repeat;
        background-size: cover;
      }
      .footer_link_tab:nth-of-type(3) {
        background: url(${process.env.REACT_APP_IMGBASE}/administrator/data/upload/portal/resource/img/home/index_50.png) 0 0 no-repeat;
        background-size: cover;
      }
      .footer_link_tab:nth-of-type(4) {
        background: url(${process.env.REACT_APP_IMGBASE}/administrator/data/upload/portal/resource/img/home/index_52.png) 0 0 no-repeat;
        background-size: 100% 100%;
      }
    }
    .yyry {
      width: 1240px;
      margin: 30px auto;
      border-radius: 6px;
      .yyry_img {
        width: 100%;
        border-radius: 6px;
        overflow: hidden;
        display: block;
        img {
          width: 100%;
        }
      }
    }
    .zltj_list {
      width: 1240px;
      overflow: hidden;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      flex-wrap: wrap;
      margin: 0 auto -20px;
      .zltj_tab {
        width: 610px;
        height: 320px;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center center;
        border-radius: 6px;
        margin-bottom: 20px;
        .zltjMask {
          width: 100%;
          height: 100%;
          /* background:rgba(114,114,114,.4); */
          display: flex;
          justify-content: center;
          align-items: center;
          .zltj_content {
            width: 204px;
            height: 204px;
            background: ${red};
            border-radius: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            .zltj_title {
              width: 135px;
              text-align: center;
              font-size: 32px;
              line-height: 36px;
              font-weight: bold;
              color: #fff;
            }
          }
        }
      }
    }
    // 正在热映
    .zzry_ttyy_link {
      width: 100%;
      a {
        display: block;
        width: 335px;
        text-align: center;
        font-size: 28px;
        color: #fff;
        margin: 20px auto 0px;
        padding: 5px 10px;
        border-radius: 15px;
        background: ${red};
          &:hover {
            color: #fff;
          }
        }
      }
    }
    .zzry {
      width: 1340px;
      margin: 0 auto;
      overflow: hidden;
      .zzry_sw {
        width: 100%;
        height: 460px;
      }
    }
    .jxhd {
      width: 1340px;
      margin: 0 auto;
      overflow: hidden;
      .jxhd_sw {
        width: 100%;
        height: 460px;
      }
    }
    .news_wrap {
      width: 100%;
      height: 543px;
      /* background:url(${process.env.REACT_APP_IMGBASE}/administrator/data/upload/portal/resource/img/home/index_21.png) right top no-repeat;
            background-size:auto 440px; */
      .news_content {
        width: 1240px;
        margin: 0 auto;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        .hdyg {
          width: 383px;
          /* border: 2px solid #c3e9ed; */
          border:2px solid ${red};
          box-sizing: border-box;
          border-radius: 6px;
          .hdyg_list {
            width: 340px;
            margin: 30px auto;
            overflow: hidden;
            .hdyg_link {
              width: 100%;
              height: 45px;
              .time {
                width: 52px;
                height: 46px;
                border-radius: 7px;
                overflow: hidden;
                background: #4d6477;
                box-sizing: border-box;
                .day {
                  width: 52px;
                  font-size: 18px;
                  color: #4d6477;
                  line-height: 25px;
                  text-align: center;
                  height: 25px;
                  font-weight: bold;
                  background: #fff;
                }
                .year {
                  width: 52px;
                  height: 21px;
                  font-size: 9px;
                  text-align: center;
                  line-height: 21px;
                  color: #ffffff;
                  margin: 0;
                }
              }
              .article {
                line-height: 45px;
                height: 45px;
                font-size: 16px;
                color: #3a4045;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
              }
            }
            .hdyg_a_article{
                line-height: 45px;
                height: 45px;
                font-size: 16px;
                color: #3a4045;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
            }
          }
        }
        .tzgg {
          width: 383px;
          /* border: 2px solid #c3e9ed; */
          border:2px solid ${red};
          box-sizing: border-box;
          border-radius: 6px;
          .tzgg_list {
            width: 340px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            margin: 20px auto;
            .tzgg_link {
              width: 340;
              line-height: 45px;
              height: 45px;
              font-size: 16px;
              color: #3a4045;
              margin-left: 9px;
              display: block;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
              display: flex;
              align-items:center;
              span{
                width:16px;
                img{
                  width:16px;
                }
              }
            }
          }
        }
        .xwzx {
          width: 383px;
          /* border: 2px solid #c3e9ed; */
          border:2px solid ${red};
          box-sizing: border-box;
          border-radius: 6px;
          .hdyg_list {
            width: 340px;
            margin: 30px auto;
            overflow: hidden;
            .hdyg_link {
              width: 100%;
              height: 45px;
              .time {
                width: 52px;
                height: 46px;
                border-radius: 7px;
                overflow: hidden;
                background: #4d6477;
                box-sizing: border-box;
                .day {
                  width: 52px;
                  font-size: 18px;
                  color: #4d6477;
                  line-height: 25px;
                  text-align: center;
                  height: 25px;
                  font-weight: bold;
                  background: #fff;
                }
                .year {
                  width: 52px;
                  height: 21px;
                  font-size: 9px;
                  text-align: center;
                  line-height: 21px;
                  color: #ffffff;
                  margin: 0;
                }
              }
              .article {
                line-height: 45px;
                height: 45px;
                font-size: 16px;
                color: #3a4045;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
              }
            }
            .hdyg_a_article{
                line-height: 45px;
                height: 45px;
                font-size: 16px;
                color: #3a4045;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
            }
          }
        }
      }
    }
    .banner {
      width: 100%;
      height: 480px;
    }
    .index_part_2 {
      width: 1240px;
      height: 265px;
      margin: 60px auto;
      display: flex;
      flex-direction: row;
      .today_part {
        width: 450px;
        height: 265px;
        background: #ebeaea;
        border-radius: 6px;
        overflow: hidden;
        .title {
          width: 100%;
          height: 55px;
          background: ${red};
          font-size: 32px;
          color: #fff;
          font-weight: bold;
          line-height: 55px;
          text-align: center;
        }
        .today_p_bot {
          width: 100%;
          height: 210px;
          display: flex;
          flex-direction: row;
          overflow: hidden;
          a {
            width: 240px;
            height: 180px;
            margin: 15px;
            display: block;
            img {
              width: 100%;
            }
          }
          .font {
            flex: 1;
            margin-top: 15px;
            .date {
              font-size: 95px;
              line-height: 140px;
              height: 140px;
              color: #3a4045;
              font-weight: bold;
              text-align: center;
            }
            .xq {
              color: #e93e3e;
              font-size: 18px;
              text-align: center;
            }
          }
        }
      }
      .schedule {
        width: 240px;
        height: 240px;
        margin-left: 40px;
        .schedule_list {
          width: 100%;
          height: 100%;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          .zcg {
            width: 238px;
            height: 89px;
            border: 1px solid ${red};
            overflow: hidden;
            border-radius: 3px;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            background: #b01604;
            .left_title {
              width: 50px;
              height: 90px;
              background: ${red};
              font-size: 20px;
              font-weight: bold;
              color: #fff;
              line-height: 20px;
              display: flex;
              align-items: center;
              justify-content: center;
            }
            .right_time {
              flex: 1;
              display: flex;
              align-items: center;
              justify-content: center;
              flex-direction: column;
              background:#fff;
              .top_span {
                font-size: 20px;
                color: #3a4045;
                display: block;
              }
              .addspan {
                font-size: 12px;
              }
              .red_span {
                color: #5d7b93;
                font-size: 14px;
                display: block;
              }
            }
          }
          .etly {
            width: 238px;
            height: 100px;
            border: 1px solid ${red};
            overflow: hidden;
            border-radius: 3px;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            background: #b01604;
            .left_title {
              width: 50px;
              height: 100%;
              background: ${red};
              font-size: 20px;
              font-weight: bold;
              color: #fff;
              line-height: 20px;
              display: flex;
              align-items: center;
              justify-content: center;
            }
            .right_time {
              flex: 1;
              display: flex;
              align-items: center;
              justify-content: center;
              flex-direction: column;
              background:#fff;
              .top_span {
                font-size: 20px;
                color: #3a4045;
                display: block;
              }
              .addspan {
                font-size: 12px;
              }
              .red_span {
                color: #5d7b93;
                font-size: 14px;
                display: block;
              }
            }
          }
          .schedule_time {
            width: 100%;
            height: 20px;
            /* display:flex;
                        flex-direction:row; */
            align-items: center;
            margin-top: 20px;
            display: flex;
            flex-direction:row;
            span {
              font-size: 18px;
              color: #3a4045;
              line-height: 20px;
              display: flex;
              flex-direction: row;
              span {
                font-size: 14px;
                color: #333;
              }
            }
            i {
              margin-right: 5px;
              display: flex;
              align-items: center;
              justify-content: center;
            }
            .link {
              color: #e93e3e;
              text-decoration: underline;
              margin-left: 20px;
              font-size: 15px;
              line-height: 25px;
            }
          }
        }
        .schedule_bot {
          width: 100%;
          margin-top: 5px;
          overflow: hidden;
          .line_1 {
            line-height: 26px;
            font-size: 16px;
            color: #3a4045;
          }
          span {
            height: 15px;
            font-size: 15px;
            color: #e93e3e;
            margin-top: 10px;
            line-height: 15px;
            display: block;
          }
        }
      }
      .address {
        width: 470px;
        overflow: hidden;
        margin-left: 40px;
        .top {
          width: 100%;
          height: 44px;
          line-height: 40px;
          display: flex;
          flex-direction: row;
          border-bottom: 1px solid #8b98a2;
          h2 {
            font-size: 24px;
            color: #4d6477;
            font-weight: bold;
          }
          h3 {
            background: url(${process.env.REACT_APP_IMGBASE}/administrator/data/upload/portal/resource/img/home/index_05.png) 10px 6px no-repeat;
            background-size: 32px 32px;
            text-indent: 53px;
            font-size: 18px;
            height: 44px;
            color: #616568;
          }
        }
        .line {
          .line_section {
            width: 100%;
            overflow: hidden;
            color: #3a4045;
            .title {
              font-size: 19px;
              line-height: 28px;
              height: 28px;
              display: flex;
              justify-items: center;
              margin-top: 15px;
              span {
                margin-left: 5px;
              }
            }
            .detail {
              font-size: 15px;
              line-height: 15px;
              height: 15px;
              text-indent: 38px;
              margin-top: 15px;
            }
          }
        }
        .links {
          width: 430px;
          height: 48px;
          display: flex;
          margin: 20px auto;
          justify-content: space-between;
          a {
            width: 190px;
            height: 48px;
            background:${red};
            border-radius:15px;
            display: flex;
            flex-direction:row;
            align-items:center;
            justify-content:center;
            color:#fff;
            font-size:28px;
            svg{
              width:38px;
              margin-right:5px;
            }
            /* img {
              width: 100%;
              height: 100%;
            } */

          }
        }
      }
    }
  }
  @media (max-width: 750px) {
    display: none;
  }
`

export const IndexWrapM = styled.div `
  @font-face {
    font-family: 'homeFont';
    src: url('${process.env.REACT_APP_IMGBASE}/administrator/data/upload/portal/resource/img/home/font.ttf') format('truetype');
  }
  @media (max-width: 750px) {
    width: 100%;
    overflow: hidden;
    .m_title {
      font-size: 0.32rem;
      font-weight: bold;
      line-height: 0.32rem;
      color: #fff;
      display: flex;
      flex-direction: row;
      align-items: center;
      margin: 0.2rem;
      .i_small2 {
        width: 0.16rem;
        height: 0.16rem;
        background: #f55c5d;
      }
      .i_large2 {
        width: 0.3rem;
        height: 0.3rem;
        background: ${red};
        margin-right: 0.07rem;
      }
      span {
        padding: 0.1rem 0.4rem;
        background: ${red};
        border-radius: 5px;
        margin: 0 0.16rem 0 0;
        box-sizing: border-box;
      }
    }
    .hdxt1{
      width:94vw;
      display:block;
      margin:.2rem auto;
      border-radius:.1rem;
    }
    .yqlj {
      width: 7.1rem;
      margin: 0rem auto;
      .yqlj_title {
        font-size: 0.3rem;
        color: ${red};
        // color:#fff;
        font-weight: bold;
      }
      .yqlj_list {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        flex-wrap: wrap;
        .yqlj_link {
          font-size: 0.26rem;
          color: ${red};
          font-weight: bold;
          margin: 0.2rem;
          // padding: 0.08rem 0.04rem;
          // line-height: 0.3rem;
          // margin-top: 0.01rem;
          // display: inline-block;
        }
        /* .yqlj_link:hover{
                    background:${red};
                    color:#fff;
                } */
      }
    }

    .new_title {
      font-size: 30px;
      color: ${red};
      margin: 50px auto 30px;
      font-weight: bold;
    }
    .footer_link {
      width: 7.1rem;
      margin: 0.2rem auto 0.2rem;
      display: flex;
      flex-direction: row;
      // justify-content: space-between;
      justify-content:flex-start;
      .footer_link_tab {
        width: 1.72rem;
        height: 0.66rem;
        font-size: 0.24rem;
        line-height: 0.66rem;
        text-align: center;
        color: #fff;
        border-radius: 0.04rem;
        margin-right:0.2rem;
      }
      .footer_link_tab:nth-of-type(1) {
        background: url(${process.env.REACT_APP_IMGBASE}/administrator/data/upload/portal/resource/img/home/index_45.png) 0 0 no-repeat;
        background-size: cover;
      }
      .footer_link_tab:nth-of-type(2) {
        background: url(${process.env.REACT_APP_IMGBASE}/administrator/data/upload/portal/resource/img/home/index_47.png) 0 0 no-repeat;
        background-size: cover;
      }
      .footer_link_tab:nth-of-type(3) {
        background: url(${process.env.REACT_APP_IMGBASE}/administrator/data/upload/portal/resource/img/home/index_50.png) 0 0 no-repeat;
        background-size: cover;
      }
      .footer_link_tab:nth-of-type(4) {
        background: url(${process.env.REACT_APP_IMGBASE}/administrator/data/upload/portal/resource/img/home/index_52.png) 0 0 no-repeat;
        background-size: 100% 100%;
      }
    }
    .yyry {
      width: 7.1rem;
      margin: 0rem auto 0;
      display: block;
      overflow: hidden;
      border-radius: 5px;
      .yyry_img {
        width: 100%;
        img {
          width: 100%;
        }
      }
    }
    .zltj_list {
      width: 7.1rem;
      overflow: hidden;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      flex-wrap: wrap;
      margin: 0 auto;
      .zltj_tab {
        width: 3.46rem;
        height: 2.18rem;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center center;
        border-radius: 0.13rem;
        margin-bottom: 0.2rem;
        .zltjMask {
          width: 100%;
          height: 100%;
          /* background:rgba(114,114,114,.4); */
          display: flex;
          justify-content: center;
          align-items: center;
          .zltj_content {
            width: 1.8rem;
            height: 1.8rem;
            background: rgba(0, 176, 220, 0.7);
            border-radius: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            img {
              width: 1rem;
            }
            .zltj_title {
              width: 1.35rem;
              text-align: center;
              font-size: 0.32rem;
              line-height: 0.36rem;
              font-weight: bold;
              color: #fff;
            }
          }
        }
      }
    }

    .news_wrap {
      width: 100%;
      overflow: hidden;
      .news_content {
        width: 100%;
        overflow: hidden;
        .news_part_1 {
          width: 100%;
          background: ${red};
          padding: 0.3rem 0.2rem;
          box-sizing: border-box;
          .news_part_1_tab {
            width: 100%;
            display: flex;
            flex-direction: row;
            font-weight: bold;
            line-height: 0.52rem;
            font-size: 0.32rem;
            justify-content: center;
            .news_tab_1 {
              width: 2.82rem;
              height: 0.52rem;
              background: url(${process.env.REACT_APP_IMGBASE}/administrator/data/upload/portal/resource/img/home/tags_9.png) 0 0 no-repeat;
              background-size: 100% 100%;
              color: #fff;
              text-indent: 0.7rem;
            }
            .news_tab_2 {
              width: 2.82rem;
              height: 0.52rem;
              background: url(${process.env.REACT_APP_IMGBASE}/administrator/data/upload/portal/resource/img/home/tags_10.png) 0 0 no-repeat;
              background-size: 100% 100%;
              text-indent: 0.78rem;
              color: #fff;
              margin-left: -0.2rem;
            }
            .news_tab_1_active {
              width: 2.82rem;
              height: 0.52rem;
              background: url(${process.env.REACT_APP_IMGBASE}/administrator/data/upload/portal/resource/img/home/home_26.png) 0 0 no-repeat;
              background-size: 100% 100%;
              color: #70c1f8;
              text-indent: 0.7rem;
            }
            .news_tab_2_active {
              width: 2.82rem;
              height: 0.52rem;
              background: url(${process.env.REACT_APP_IMGBASE}/administrator/data/upload/portal/resource/img/home/home_19.png) 0 0 no-repeat;
              background-size: 100% 100%;
              text-indent: 0.78rem;
              color: #70c1f8;
              margin-left: -0.2rem;
            }
          }
          .hdyg {
            width: 6.1rem;
            margin: 0rem auto 0;
            .hdyg_list {
              width: 100%;
              overflow: hidden;
              .hdyg_link {
                width: 100%;
                padding: 0.43rem 0 0.23rem;
                box-sizing: border-box;
                border-bottom: 0.02rem solid rgba(255,255,255,0.5);
                display: block;
                .time {
                  font-size: 0.28rem;
                  color: #fff;
                  text-align: left;
                  margin-top: 0.3rem;
                  text-indent: 0.39rem;
                }
                .article {
                  display: flex;
                  flex-direction: row;
                  i {
                    width: 0.19rem;
                    height: 0.19rem;
                    border-radius: 100%;
                    background: #fff;
                    display: inline-block;
                    margin-right: 0.2rem;
                    margin-top: 0.12rem;
                  }
                  img{
                    width:.3rem;
                    height:.3rem;
                    margin-top: .1rem;
                  }
                  span {
                    flex: 1;
                    line-height: 0.44rem;
                    font-size: 0.3rem;
                    color: #fff;
                  }
                }
              }
            }
          }
          .tzgg {
            width: 6.1rem;
            margin: 0rem auto 0;
            .tzgg_list {
              width: 100%;
              overflow: hidden;
              .hdyg_link {
                width: 100%;
                padding: 0.43rem 0 0.23rem;
                box-sizing: border-box;
                border-bottom: 0.02rem solid rgba(255,255,255,0.5);
                display: block;
                .time {
                  font-size: 0.28rem;
                  color: #fff;
                  text-align: left;
                  margin-top: 0.3rem;
                  text-indent: 0.39rem;
                }
                .article {
                  display: flex;
                  flex-direction: row;
                  i {
                    width: 0.19rem;
                    height: 0.19rem;
                    border-radius: 100%;
                    background: #fff;
                    display: inline-block;
                    margin-right: 0.2rem;
                    margin-top: 0.12rem;
                  }
                  img{
                    width:.3rem;
                    height:.3rem;
                    margin-top: .1rem;
                  }
                  span {
                    flex: 1;
                    line-height: 0.44rem;
                    font-size: 0.3rem;
                    color: #fff;
                  }
                }
              }
            }
          }
        }
        .xwzx {
          width: 7.1rem;
          margin: 0.55rem auto 0;
          .xwzx_list {
            width: 100%;
            overflow: hidden;
            .xwzx_link {
              display: flex;
              flex-direction: row;
              justify-content: space-between;
              padding: 0.3rem 0;
              border-bottom: 1px solid #a0a0a0;
              border-bottom: 0.01rem dashed #a0a0a0;
              box-sizing: border-box;
              .xwzx_link_img {
                width: 2.4rem;
                height: 1.55rem;
                display: flex;
                justify-content: center;
                align-items: center;
                border-radius: 5px;
                overflow: hidden;
                img {
                  height: 100%;
                  width: 100%;
                }
              }
              .xwzx_text_wrap {
                width: 4.4rem;
                height: 1.55rem;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                .xwzx_title {
                  color: #525252;
                  font-size: 0.3rem;
                }
                .xwzx_time {
                  color: #009cbe;
                  text-align: left;
                }
              }
            }
          }
        }
      }
    }
    .banner {
      width: 100%;
      height: 4.2rem;
    }
    .index_part_2 {
      width: 7.1rem;
      margin: 0.3rem auto 0.4rem;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      .today_part {
        width: 3.5rem;
        background: #ebeaea;
        border-radius: 0.06rem;
        overflow: hidden;
        .title {
          width: 100%;
          height: 0.8rem;
          background: ${red};
          font-size: 0.25rem;
          color: #fff;
          font-weight: bold;
          line-height: 0.8rem;
          text-align: center;
        }
        .today_p_bot {
          width: 100%;
          height: 3.1rem;
          display: flex;
          flex-direction: row;
          overflow: hidden;
          align-items: center;
          a {
            width: 4rem;
            margin: 0.15rem;
            display: block;
            img {
              width: 100%;
            }
          }
          .font {
            flex: 1;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            .date {
              font-size: 0.95rem;
              line-height: 1.4rem;
              height: 1.4rem;
              color: #3a4045;
              font-weight: bold;
              text-align: center;
            }
            .xq {
              color: #e93e3e;
              font-size: 0.3rem;
              text-align: center;
              font-weight: bold;
            }
          }
        }
      }
      .schedule {
        width: 3.4rem;
        .schedule_list {
          width: 3.34rem;
          .zcg {
            width: 100%;
            height: 75px;
            border: 2px solid ${red};
            overflow: hidden;
            border-radius: 5px;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            margin-bottom: 0.2rem;
            overflow: hidden;
            background: ${red};
            .left_title {
              width: 25px;
              height: 75px;
              background: ${red};
              font-size: 16px;
              font-weight: bold;
              color: #fff;
              line-height: 16px;
              display: flex;
              align-items: center;
              justify-content: center;
            }
            .right_time {
              flex: 1;
              display: flex;
              align-items: center;
              justify-content: center;
              flex-direction: column;
              background-color:#fff;
              .top_span {
                font-size: 0.28rem;
                color: #3a4045;
                margin-top: 0.1rem;
              }
              .red_span {
                font-size: 0.28rem;
                color: #3a4045;
                margin-top: 0.1rem;
              }
              .top_span1 {
                margin-top: 0.1rem;
                display: flex;
                flex-direction: row;
                i {
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  margin-right: 0.06rem;
                }
              }
            }
          }
          .etly {
            width: 100%;
            height: 80px;
            border: 2px solid ${red};
            overflow: hidden;
            border-radius: 5px;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            overflow: hidden;
            background: ${red};
            .left_title {
              width: 25px;
              height: 80px;
              background: ${red};
              font-size: 16px;
              font-weight: bold;
              color: #fff;
              line-height: 16px;
              display: flex;
              align-items: center;
              justify-content: center;
            }
            .right_time {
              flex: 1;
              color: #3a4045;
              display: flex;
              align-items: center;
              justify-content: center;
              flex-direction: column;
              background:#fff;
              .top_span {
                font-size: 0.28rem;
                color: #3a4045;
              }
              .red_span {
                font-size: 0.28rem;
                color: #3a4045;
              }
            }
          }
          .top_span1{
            margin-top: 10px;
            display: flex;
            float: left;
          }
          .link{
            float: left;
            margin: 10px 0 0 10px;
          }
          .schedule_time {
            width: 100%;
            height: 20px;
            span {
              font-size: 0.22rem;
              color: #3a4045;
              line-height: 20px;
            }
            i {
              margin-right: 5px;
            }
            .link {
              color: #e93e3e;
              text-decoration: underline;
              margin-left: 20px;
              font-size: 0.22rem;
            }
          }
        }
        .schedule_bot {
          width: 100%;
          margin-top: 0;
          overflow: hidden;
          .line_1 {
            line-height: 0.35rem;
            font-size: 0.22rem;
            color: #3a4045;
          }
          span {
            font-size: 0.15rem;
            color: #e93e3e;
            margin-top: 0.1rem;
            line-height: 0.15rem;
            display: block;
          }
        }
      }
    }
    .address {
      width: 7.1rem;
      overflow: hidden;
      margin: 0 auto;
      .top {
        width: 100%;
        height: 0.6rem;
        line-height: 0.6rem;
        display: flex;
        flex-direction: row;
        border-bottom: 1px solid #8b98a2;
        h2 {
          font-size: 0.3rem;
          color: #4d6477;
          font-weight: bold;
        }
        h3 {
          background: url(${process.env.REACT_APP_IMGBASE}/administrator/data/upload/portal/resource/img/home/index_05.png) 0.1rem 0.16rem no-repeat;
          background-size: 0.32rem 0.32rem;
          text-indent: 0.53rem;
          font-size: 0.23rem;
          height: 0.6rem;
          color: #616568;
        }
      }
      .line {
        margin-bottom: 0.2rem;
        .line_section {
          width: 100%;
          overflow: hidden;
          color: #3a4045;
          .title {
            font-size: 0.26rem;
            line-height: 0.35rem;
            height: 0.35rem;
            display: flex;
            justify-items: center;
            margin-top: 0.15rem;
            i {
              width: 0.35rem;
              img {
                width: 100%;
              }
            }
            span {
              margin-left: 0.08rem;
            }
          }
          .detail {
            font-size: 0.26rem;
            line-height: 0.35rem;
            height: 0.35rem;
            text-indent: 0.38rem;
            margin-top: 0.15rem;
          }
        }
      }
      .links {
        width: 7.1rem;
        height: 0.48rem;
        display: flex;
        margin: 0.2rem auto 0.57rem;
        justify-content: space-between;
        a {
          width: 3.3rem;
          height: 0.78rem;
          img {
            width: 100%;
            height: 100%;
          }
        }
      }
    }
    .zzry_ttyy_link {
      width: 100%;
      height: 1rem;
      a {
        display: block;
        width: 50%;
        text-align: center;
        font-size: 0.3rem;
        font-weight: bold;
        color: #fff;
        margin: 0.2rem auto 0rem;
        padding: 0.1rem;
        border-radius: 0.1rem;
        background: ${red};
          &:hover {
            color: #fff;
          }
        }
      }
    }
    .zzry {
      width: 7.1rem;
      margin: 0 auto;
      .sw {
        width: 100%;
        height: 10rem;
        margin-top: 0.3rem;
      }
    }
    .jxhd {
      width: 7.1rem;
      margin: 0 auto;
      .sw {
        width: 100%;
        height: 4rem;
        margin-top: 0.3rem;
      }
    }
  }
  @media (min-width: 750px) {
    display: none;
  }
`
