import React,{Fragment} from 'react';
import {ServiceWarp,ServiceWarpM} from './style'
import CommonCarousel from 'common/carousel.js'
import {connect} from 'react-redux';
import {withRouter,NavLink} from 'react-router-dom';
import { actions } from './store'
import {Skeleton} from 'antd'
import NavSection from 'common/navSection'

const m_title = (font)=>{
    return(
        <div className='m_title'>
            <span>
                {font}
            </span>
            <i className="i_large2"></i>
            <i className="i_small2"></i>
        </div>
    )
}
class Service extends React.Component{
    constructor(props){
        super(props)
        this.state = {
            serverList:null
        }
    }
    componentDidMount(){
        document.title = '参观服务'
        if(!this.props.serviceArr){
            this.props.serverInit()
        }
    }
    zxyd(arr){
        let zxydList = []
        for(let item in arr){
            if(item !== 'cat_name'){
                zxydList.push(arr[item])
            }
        }
        return zxydList
    }
    render(){
        return(
            this.props.serviceArr?
            <Fragment>
                <NavSection />
                <ServiceWarp>
                    <a className="header" href='https://ticket.bjsc.net.cn/' target='_blank'>
                        {/* <CommonCarousel className='sw'  Carouselarr={this.zxyd(this.props.serviceArr.zxyd)} /> */}
                        <img src={this.props.serviceArr.zxyd[0].path} alt=""/>
                    </a>
                    <div className="tab">
                        {
                            this.props.serviceArr.geryd.map((item)=>{
                                if(item.posts_link){
                                    return(
                                        <a className='tab_div' href={item.posts_link} target={item.posts_link.indexOf(location.host)>=0?'':'_blank'}><img src={item.path} alt=""/></a>
                                    )
                                }else{
                                    return(
                                        <NavLink className="tab_div" to={'/article/'+item.id} key={item.id}>
                                            <img src={item.path} alt=""/>
                                        </NavLink>
                                    )
                                }

                            })
                        }
                    </div>
                    <div className="open_time">
                        {
                            this.zxyd(this.props.serviceArr.kfsj).map((item,index)=>{
                                let colorList = ['rgba(208,102,120,.8)','rgba(240,175,78,.8)','rgba(34,159,238,.8)','rgba(254,73,52,.6)','rgba(227,91,70,.6)','rgba(172,100,35,.6)'];
                                let colorArr = ['#c86273','#ffb341','#219ded','#fe4934','#e35b46','#ac6423'];
                                let _style = {
                                        backgroundImage:'url('+item.path+')'
                                };
                                let _bgColor = {
                                        background:colorList[index]
                                };
                                let _color = {
                                        color:colorArr[index]
                                };
                                return(
                                    <div className="open_time_sections" style={_style} key={item.id}>
                                        <div className="open_time_sections_mask" style={_bgColor}>
                                            <span className="name" style={_color} dangerouslySetInnerHTML={{ __html: item.post_content }}></span>
                                            <div className='sec'>{item.post_excerpt}</div>
                                            <div className='sec'>{item.posts_duration}</div>
                                            <div className='sec'>{item.posts_afternoon}</div>
                                            <div className='sec'>{item.posts_stop}</div>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                    <div className="traffic_route">
                        <div className="traffic_route_content">
                            <p dangerouslySetInnerHTML={{__html:this.props.serviceArr.jtlx[0].post_content }}></p>
                        </div>
                        <div className="traffic_route_map">
                            <a className="map_link" href='https://ditu.amap.com/place/B0FFHWMKEX' target='_blank'>

                            </a>
                            <iframe src='https://m.amap.com/navi/?start=&dest=116.393275,39.970341&destName=%E5%8C%97%E4%BA%AC%E7%A7%91%E5%AD%A6%E4%B8%AD%E5%BF%83&key=b5ff71f1ea7cd627560776ab1325c497'/>
                        </div>
                    </div>
                </ServiceWarp>
                <ServiceWarpM>
                     {/* <CommonCarousel className='sw'  Carouselarr={this.zxyd(this.props.serviceArr.zxyd)} /> */}
                    <a className="header" href='https://ticket.bjsc.net.cn/' target='_blank'>
                        <img src={this.props.serviceArr.zxyd[0].path} alt=""/>
                    </a>
                    <div className="tab">
                        {
                            this.props.serviceArr.geryd.map((item)=>{
                                // return(
                                //     <NavLink className="tab_div" to={'/article/'+item.id} key={item.id}>
                                //         <img src={item.path} alt=""/>
                                //     </NavLink>
                                // )
                                if(item.posts_link){
                                    return(
                                        <a className='tab_div' href={item.posts_link} target={item.posts_link.indexOf(location.host)>=0?'':'_blank'}><img src={item.path} alt=""/></a>
                                    )
                                }else{
                                    return(
                                        <NavLink className="tab_div" to={'/article/'+item.id} key={item.id}>
                                            <img src={item.path} alt=""/>
                                        </NavLink>
                                    )
                                }
                            })
                        }
                    </div>
                    <div className="open_time">
                            <div className="open_part">
                                {
                                    m_title('开放时间')
                                }
                            </div>
                            {
                                    this.zxyd(this.props.serviceArr.kfsj).map((item,index)=>{
                                        let colorList = ['rgba(208,102,120,.8)','rgba(240,175,78,.8)','rgba(34,159,238,.8)','rgba(254,73,52,.6)','rgba(227,91,70,.6)','rgba(172,100,35,.6)'];
                                        let colorArr = ['#c86273','#ffb341','#219ded','#fe4934','#e35b46','#ac6423'];
                                        let _style = {
                                                backgroundImage:'url('+item.path+')'
                                        };
                                        let _bgColor = {
                                                background:colorList[index]
                                        };
                                        let _color = {
                                                color:colorArr[index]
                                        };
                                        return(
                                            <div className="open_time_sections" style={_style} key={item.id}>
                                                <div className="open_time_sections_mask" style={_bgColor}>
                                                    <span className="name" style={_color} dangerouslySetInnerHTML={{ __html: item.post_content }}></span>
                                                    <div className='sec'>{item.post_excerpt}</div>
                                                    <div className='sec'>{item.posts_duration}</div>
                                                    <div className='sec'>{item.posts_afternoon}</div>
                                                    <div className='sec'>{item.posts_stop}</div>
                                                </div>
                                            </div>
                                        )
                                    })
                            }
                    </div>
                    <div className="traffic_route">
                        <div className="traffic_title">
                            {
                                m_title('交通路线')
                            }
                        </div>

                        <div className="traffic_route_map">
                            <a className="map_link" href='https://ditu.amap.com/place/B0FFHWMKEX' target='_blank'></a>
                            <iframe src='https://m.amap.com/navi/?start=&dest=116.393275,39.970341&destName=%E5%8C%97%E4%BA%AC%E7%A7%91%E5%AD%A6%E4%B8%AD%E5%BF%83&key=b5ff71f1ea7cd627560776ab1325c497'/>
                        </div>
                        <div className="traffic_route_content">
                            <p dangerouslySetInnerHTML={{__html:this.props.serviceArr.jtlx[0].post_content }}></p>
                        </div>
                    </div>
                </ServiceWarpM>
            </Fragment>:<Skeleton active />
        )
    }
}
const mapStateToProps = (state)=>{
    return{
        serviceArr:state.getIn(['service','serviceArr'])
    }
}
const mapDispatchToProps = (dispatch)=>{
    return{
        serverInit(){
            dispatch(actions.getService())
        }
    }
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Service))
