import React, { memo, useEffect, useState } from 'react'
import { DqfyWrap } from './style'
import { Skeleton } from 'antd';
import CommonCarousel from './../lszl/slide'
import SectionWrap from 'common/newSection'
import NavSection from 'common/navSection'
import CommonCarouselm from './../lszl/slidem'
import { NavLink } from 'react-router-dom'

const Dqfy = memo(() => {
  const [state, setState] = useState({})
  const [iframeShow, setis] = useState(false)
  useEffect(() => {
    React.$request({
      url: 'Portal/index/exhibition_hall_son',
      method: 'get',
      params: {
        termid: 282
      }
    }).then(res => {
      console.log(res.data)
      setState(res.data)
    })
  }, [])
  return (
    <>
      {
        state.zqjs ?
          <DqfyWrap style={{ "backgroundImage": `url(${process.env.REACT_APP_IMGBASE}/administrator/data/upload/portal/resource/img/exhibition/dqfy/ztbg_01.png)` }}>
            <NavSection />
            {
              iframeShow &&
              <div className="iframeWrap">
                <div className="closeiframe" onClick={() => setis(false)}>
                  <svg t="1619083375449" class="icon" viewBox="0 0 1025 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="1988" width="64" height="64"><path d="M513.344 0a512 512 0 1 0 0 1024 512 512 0 0 0 0-1024z m226.048 674.624l-54.528 56.896-171.52-164.928-171.392 164.928-54.592-56.896L456.576 512 287.36 349.312l54.592-56.768 171.392 164.8 171.52-164.8 54.528 56.768L570.176 512l169.216 162.624z" fill="#ffffff" p-id="1989"></path></svg>
                </div>
                <iframe src="https://www.bjsc.net.cn/360vr/dqfyz/tour.html" frameborder="0" className='iframe'></iframe>
              </div>
            }
            <div className="top">
              <div className="top_left_img" onClick={() => setis(true)}>
                <img src={state.zqjs[1][0].path} alt="" />
              </div>
              <div className="top_right">
                <div className="top_right_title" style={{ "backgroundImage": `url(${process.env.REACT_APP_IMGBASE}/administrator/data/upload/portal/resource/img/exhibition/dqfy/indexIcons_03.png)` }}>
                  地球方圆主题展
                </div>
                <div className="address" style={{ "backgroundImage": `url(${process.env.REACT_APP_IMGBASE}/administrator/data/upload/portal/resource/img/exhibition/dqfy/indexIcons_07.png)` }}>
                  地点：{state.zqjs[0][0].posts_address}
                </div>
                <div className="content"
                  dangerouslySetInnerHTML={{
                    __html: state.zqjs[0][0].post_content
                  }}
                >
                </div>
              </div>
            </div>
            <div className="ptitle">
              <img src={`${process.env.REACT_APP_IMGBASE}/administrator/data/upload/portal/resource/img/exhibition/dqfy/indexIcons_11.png`} alt="" />
            </div>
            <div className="part2">
              <div className="p2left" style={{ 'backgroundImage': `url(${process.env.REACT_APP_IMGBASE}/administrator/data/upload/portal/resource/img/exhibition/dqfy/leftbox_03.png)` }}>
                <h2>{state.ldxfy.gs[0].post_title}</h2>
                <div className="content"
                  dangerouslySetInnerHTML={{
                    __html: state.ldxfy.gs[0].post_content
                  }}>
                </div>
              </div>
              <div
                className="pconent phide"
                style={{ "backgroundImage": `url(${process.env.REACT_APP_IMGBASE}/administrator/data/upload/portal/resource/img/exhibition/dqfy/indexIcons_15.png)` }}
                dangerouslySetInnerHTML={{
                  __html: state.ldxfy.gs[0].post_content
                }}
              >
              </div>
              <div className="p2list">
                {state.ldxfy.js.slice(0, 4).map((item, index) => {
                  return (
                    <NavLink className="tab" style={{ 'backgroundImage': 'url(' + item.path + ')' }} key={index} to={"/article/" + item.id}>
                      <div className="title">
                        {item.post_title}
                      </div>
                      <div className="abstra">
                        {item.post_excerpt}
                      </div>
                    </NavLink>
                  )
                })}
              </div>
            </div>
            <div className="ptitle">
              <img src={`${process.env.REACT_APP_IMGBASE}/administrator/data/upload/portal/resource/img/exhibition/dqfy/indexIcons_19.png`} alt="" />
            </div>
            <div className="part1">
              <div
                className="pconent"
                style={{ "backgroundImage": `url(${process.env.REACT_APP_IMGBASE}/administrator/data/upload/portal/resource/img/exhibition/dqfy/indexIcons_15.png)` }}
                dangerouslySetInnerHTML={{
                  __html: state.ldbry.gs[0].post_content
                }}
              >
              </div>
              <div className="p1list">
                {state.ldbry.js.map((item, index) => {
                  return (
                    <NavLink className="tab" style={{ 'backgroundImage': 'url(' + item.path + ')' }} key={index} to={"/article/" + item.id}>
                      <div className="title">
                        {item.post_title}
                      </div>
                      <div className="abstra">
                        {item.post_excerpt}
                      </div>
                    </NavLink>
                  )
                })}
                <NavLink className="tab" to={"/article/1"} >
                  {/* <div className="title">
                    {item.post_title}
                  </div>
                  <div className="abstra">
                    {item.post_excerpt}
                  </div> */}
                </NavLink>
              </div>
            </div>
            <div className="ptitle">
              <img src={`${process.env.REACT_APP_IMGBASE}/administrator/data/upload/portal/resource/img/exhibition/dqfy/title2_03.png`} alt="" />
            </div>
            <div
              className="pconent"
              style={{ "backgroundImage": `url(${process.env.REACT_APP_IMGBASE}/administrator/data/upload/portal/resource/img/exhibition/dqfy/indexIcons_15.png)` }}
              dangerouslySetInnerHTML={{
                __html: state.tdmty.gs[0].post_content
              }}
            >
            </div>
            <div className="part3">
              <CommonCarousel list={state.tdmty.js} />
              <CommonCarouselm list={state.tdmty.js} />
            </div>
            <div className="part4">
              <div className="ptitle">
                <img src={`${process.env.REACT_APP_IMGBASE}/administrator/data/upload/portal/resource/img/exhibition/dqfy/title2_07.png`} alt="" />
              </div>
              <div className="hdbd_wrap">
                {
                  state.mtbd.map((item) => {
                    return (
                      <SectionWrap secarr={item} key={item.id} />
                    )
                  })
                }
              </div>
            </div>

          </DqfyWrap>
          :
          <Skeleton active />}
    </>
  )
})

export default Dqfy