import styled from 'styled-components'


export const HomeWrapM = styled.div `
    @media(min-width:750px){
        display: none;
    }
    @media(max-width:750px){
        position: fixed;
        width:100%;
        height:100%;
        background-color: #c2e7fb;
        z-index: 999;
        top: 0;
        .page-container {
            padding: 0;
            margin: 0;
            box-sizing: border-box;
            font-family: Arial, sans-serif;
        }
        .logo {
            position: fixed;
            top: 0;
            width: 35%;
            padding: 7% 0 0 5%;
            img{
                width: 100%;
            }
        }
        .background {
            position: fixed;
            bottom: 0;
            width: 100%;
            padding: 0 5% 1% 5%;
            background-size: cover;
            background-position: center;
            img{
                width: 100%;
            }
        }
        .title {
            color: #36aff2;
            text-align: center;
            font-size: 0.8rem;
            font-weight: bold;
            font-family: 'KaiTi', serif;
            padding: 0.25rem; 
            margin-top: 2rem; /* 根据实际需求调整 */
            margin-bottom: 1rem; /* 根据实际需求调整 */
        }

        .grid-container {
            // display: grid;
            margin:0 1%;
            // grid-template-columns: repeat(3, 1fr);
            // padding: 10px;
            // max-width: 600px; /* 根据屏幕尺寸调整 */
            a {
                display: block;
                float: left;
                margin: 0 0 25px 2.1%;
                width: 30.5%;
                aspect-ratio: 1 / 1; 
                // background: url('/img/weifuwu/yinying.png');
                // background-size: 100% 100%;
                :nth-child(7){
                    //margin-left: 35%;
                }
            }
            .type1{
                margin-left: 35.5%;
            }
        }

        .grid-item {
            position: relative;
            // border: 1px solid #515151; 
            display: flex;
            flex-direction: column;
            align-items: center;
            // justify-content: center;
            // padding-top: 20%;
            box-sizing: border-box;
            width: 100%;
            aspect-ratio: 1 / 1; 
        }

        .grid-item img {
            width: 100%;
            height: 100%;
        }

        .grid-item h2 {
            position: absolute;
            bottom: 0.2rem;
            margin-top: 5px;
            color: #4C4C4F;
            font-size: 0.2rem; 
            text-align: center; /* 文本居中显示 */
        }
    }
`
